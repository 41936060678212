import type { Price } from '../../api-models'
import { createHttpEndpoint } from '../../utils'

interface Product {
  productId: string
  shortTitle: string
  fullTitle: string
  image: string
}

interface Instruction {
  title: string
  paragraphs: string[]
}

interface Checkup {
  label: string
  value: string
}

export interface GetOfferV1Response {
  product: Product
  listing: {
    listingId: string
    listingPublicId: string
    price: Price
  }
  instructions: Instruction[]
  checkup: Checkup[]
  shipping: {
    hasChoice: boolean
    default: string
  }
  bankDetailsRequired: boolean
  tier?: 'low' | 'medium' | 'high' | 'great'
}

/**
 * Offer funnel V1
 *
 * @see {@link https://devportal.backmarket.io/catalog/default/api/buyback-funnel-api/definition#/Public/get_offer}
 */

export const getOfferV1 = createHttpEndpoint<GetOfferV1Response>({
  method: 'GET',
  operationId: 'getOfferV1',
  path: '/buyback-funnel/api/v1/funnel/:kind/offer',
})
