export default {
  headerTitle: {
    id: 'buyback_offer_header_title',
    defaultMessage: 'Our buyback offer',
  },
  punchline: {
    id: 'buyback_offer_punchline',
    defaultMessage: '🎉 Tadaaam!',
  },
  offer: {
    id: 'buyback_offer_offer',
    defaultMessage:
      'Refurbisher offers {amount, html} for your {model, html}. This is the highest offer from our partners.',
  },
  nextStep: {
    id: 'buyback_offer_next_step',
    defaultMessage: 'The next steps',
  },
  lastThing: {
    id: 'buyback_offer_last_thing',
    defaultMessage: 'One last thing',
  },
  lastThingParagraph: {
    id: 'buyback_offer_last_thing_paragraph',
    defaultMessage:
      'Your device will be verified once arrived in the factory. If the given informations are wrong, refurbisher will proceed to a counter offer. In case you decline it the devices shipping back will be at your charge. So just be honnest !',
  },
  decline: {
    id: 'buyback_offer_decline',
    defaultMessage: 'Decline',
  },
  accept: {
    id: 'buyback_offer_accept',
    defaultMessage: 'Accept',
  },
  loading: {
    id: 'spinner',
    defaultMessage: 'Loading',
  },
}
